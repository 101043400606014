<template>
  <div class="meal-box pb-2" >
    <!-- Show the table -->

    <b-row no-gutters class="title-meal-times title-bold mb-3">
      <b-col cols="3">
        <span>{{ totals.meal }}</span>
      </b-col>
    </b-row>
    <div
      v-for="(tot, index) in dataRowsMeal.rows"
      v-bind:key="tot.type"
      class="row-type-meal"
    >
      <b-row no-gutters>
        <b-col cols="3">
          <div class="title-meal-at-table border-meals-white">
            {{ tot.type }}
          </div>
        </b-col>
        <tot-week-cell-meals
          class="tot-meal"
          v-bind:class="{ 'border-meals': index > 0 }"
          v-for="item in tot.normal"
          v-bind:tot="item.Total"
          v-bind:date="item.Date"
          v-bind:meal="totals.meal"
          v-bind:key="item.Date"
          v-bind:past="false"
        />
      </b-row>
      <b-row no-gutters>
        <b-col cols="3" class="text-left">
          <span class="diets ml-3">diets</span>
        </b-col>
        <tot-week-cell-meals
          class="diets font-smaller"
          v-for="item in tot.diets"
          v-bind:tot="item.Diets"
          v-bind:date="item.Date"
          v-bind:meal="totals.meal"
          v-bind:description="item.Description"
          v-bind:status="item.Status"
          v-bind:key="item.Date"
          v-bind:past="false"
        />
      </b-row>
    </div>
  </div>
</template>

<script>
import TotWeekCellMeals from "./TotWeekCellMeals.vue";
import moment from "moment";

export default {
  props: ["totals"],
  components: {
    TotWeekCellMeals,
  },
  computed: {
    dataRowsMeal() {
      let d = this.$store.getters.currentWeek;
      let d1 = moment(d);
      let data = { dates: [], rows: [] };
      let dates = [];

      let rowSet = { normal: [], diets: [] };
      let rows = [];

      for (
        let datesMeals = 0;
        datesMeals < this.totals.totals.length;
        datesMeals++
      ) {
        const element = this.totals.totals[datesMeals];
        let normal = [];
        let diets = [];
        d = this.$store.getters.currentWeek;
        d1 = moment(d);
        dates = [];
        for (let index = 0; index < 7; index++) {
          let df = d1.format("YYYY-MM-DD");
          dates.push(df);
          if (df in element.normal) {
            normal.push(element.normal[df]);
          } else {
            normal.push({ Date: df, Total: 0 });
          }

          if (df in element.diets) {
            let el = element.diets[df].Diets;
            let a = el.split(", ");
            let m = new Map();
            a.forEach(function (x) {
              m.set(x, (m.get(x) || 0) + 1);
            });
            let st = "";
            let sp = "";
            m.forEach(function (v, k) {
              if (v > 1) {
                st += sp + k + "<sup>" + v + "</sup>";
              } else {
                st += sp + k;
              }
              sp = ", ";
            });
            element.diets[df].Diets = st;
            diets.push(element.diets[df]);
          } else {
            diets.push({ Date: df, Diets: "" });
          }

          d1.add(1, "days");
        }
        if (element.diets.length == 0) {
          diets = [];
        }
        rowSet = { normal: [...normal], diets: [...diets], type: element.type };
        rows.push(rowSet);
      }

      data["dates"] = dates;
      data["rows"] = rows;
      return data;
    },
  },
};
</script>

<style scoped>
.title-meal {
  color: rgb(187, 54, 54);
  text-align: left;
  font-size: 1.1em;
  margin-left: 3px;
}
.title-meal-at-table {
  color: rgb(187, 54, 54);
  text-align: left;
  font-size: 1.1em;
  margin-left: 3px;
}
.title-meal-times {
  color: rgb(48, 48, 182);
  text-align: left;
  font-size: 1.4em;
  margin-left: 3px;
}
.title-meal-times-border {
  color: rgb(48, 48, 182);
  text-align: left;
  font-size: 1.4em;
  margin-left: 3px;
}
.title-bold {
  font-weight: 600;
}
.font-smaller {
  font-size: 1em;
}

@media screen and (max-width: 520px) {
  .font-smaller {
    font-size: 0.95em;
  }
}

@media screen and (max-width: 420px) {
  .font-smaller {
    font-size: 0.9em;
  }
}

@media screen and (max-width: 385px) {
  .font-smaller {
    font-size: 0.85em;
  }
}

@media screen and (max-width: 370px) {
  .font-smaller {
    font-size: 0.8em;
  }
}

.tot-meal {
  color: rgb(187, 54, 54);
  font-weight: 600;
}
.diets {
  color: rgb(77, 77, 77);
}
.border-meals {
  border-top: 1px solid #c3c3d8;
}
.border-meals-white {
  border-top: 1px solid #ffffff;
}
.meal-box {
  border-bottom: 2px inset #a0a0ac;
}
.row-type-meal {
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>