<template>
  <b-col
    class="cell-tot"
    v-bind:class="{
      'is-before': isBefore(date),
      'is-today': isToday(date) && tot != 0,
    }"
  >
    <span v-if="tot != '0'">
      <span v-if="description">
        <span>
          <b-link
            :id="meal + '-' + status + '-' + date"
            title="Diets"
            class="ml-2"
            v-html="tot"
          ></b-link>
          <b-popover
            :target="meal + '-' + status + '-' + date"
            triggers="focus hover"
            placement="bottom"
          >
            <template #title>Diets Description</template>
            <span v-html="descriptionFormated"></span>
          </b-popover>
        </span>
      </span>
      <span v-else>
        {{ tot }}
      </span>
    </span>
    <span v-else>-</span>
  </b-col>
</template>

<script>
import moment from "moment";

export default {
  props: ["tot", "date", "meal", "description", "status"],
  computed: {
    descriptionFormated() {
      return this.description.replace(/\|/g, "<br>");
    },
  },
  methods: {
    isBefore(d) {
      let d2 = moment(d);
      switch (this.meal) {
        case "Breakfast":
          d2.add(8, "h");
          break;
        case "Lunch":
          d2.add(12, "h");
          break;
        case "Dinner":
          d2.add(18, "h");
          break;

        default:
          break;
      }
      return d2.isBefore(moment(), "minute");
    },
    isToday(d) {
      return moment(d).isSame(moment(), "day") && !this.isBefore(d);
    },
  },
};
</script>

<style scoped>
.is-before {
  color: rgb(182, 182, 182) !important;
  font-weight: 400;
}
.is-today {
  background-color: rgb(200, 255, 255);
  border-radius: 5px;
}
.tot {
  font-size: 0.8em;
}
.cell-tot {
  white-space: pre-wrap;
  padding: 0px 5px 0px 5px;
  font-size: 1.25em;
}
</style>