<style scoped>
h5 {
  color: #0066cc;
}
.total-meals-row-color {
  color: #cccccc;
}
</style>

<template>
  <div v-bind:class="{ 'total-meals-row-color': isAfterMeal }">
    <h5 v-bind:class="{ 'total-meals-row-color': isAfterMeal }" class="mt-3">{{ totals.meal }}</h5>

    <!-- Show the table -->
    <total-list-row-meal
      v-for="item in totals.totals"
      v-bind:totals="item"
      v-bind:meal="totals.meal"
      v-bind:key="item.id"
      v-bind:past="isAfterMeal"
    />
  </div>
</template>

<script>
import moment from "moment";
import TotalListRowMeal from "@/components/TotalListRowMeal.vue";

export default {
  props: ["totals", "past"],
  components: {
    TotalListRowMeal
  },
  computed: {
    currentDay() {
      return this.$store.state.currentDay;
    },
    isAfterMeal() {
      var b = false;
      switch (this.totals.meal) {
        case "Breakfast":
          b = this.isAfterBreakfast();
          break;
        case "Lunch":
          b = this.isAfterLunch();
          break;
        case "Dinner":
          b = this.isAfterDinner();
          break;
        default:
          b = false;
      }
      return b;
    }
  },
  methods: {
    isAfterBreakfast() {
      var tb = moment(this.currentDay)
        .hour(8)
        .minute(0);
      return moment().isAfter(tb);
    },
    isAfterLunch() {
      var tl = moment(this.currentDay)
        .hour(12)
        .minute(0);
      return moment().isAfter(tl);
    },
    isAfterDinner() {
      var td = moment(this.currentDay)
        .hour(18)
        .minute(0);
      return moment().isAfter(td);
    }
  }
};
</script>

