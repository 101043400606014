<template>
  <li>
    <span class="mr-3">{{ totals.Label }}:</span>
    <span
      class="total-meals-numbers-font"
      v-bind:class="{ 'total-meals-numbers-color': !past }"
    >
      <b-badge v-if="!past" variant="warning">{{ totals.Total }}</b-badge>
      <span v-else>{{ totals.Total }}</span>
      <span v-if="totals.Diets != ''">
        <b-link
          :id="meal + '-' + totals.Status"
          title="Diets"
          class="ml-2"
          v-html="stDiets(totals.Diets)"
          @click="displayDiets(totals)"
        ></b-link>
        <b-popover
          :target="meal + '-' + totals.Status"
          triggers="hover focus"
          placement="top"
        >
          <template #title>Diets Description</template>
          <div v-html="description"></div>
        </b-popover>
      </span>
    </span>
  </li>
</template>

<script>
export default {
  props: ["totals", "past", "meal"],
  computed: {
    description() {
      return this.totals.Description.replace(/\|/g, "<br>");
    },
  },
  methods: {
    stDiets(diets) {
      let a = diets.split("|");
      let m = new Map();
      a.forEach(function (x) {
        m.set(x, (m.get(x) || 0) + 1);
      });
      let st = "";
      let sp = "";
      m.forEach(function (v, k) {
        if (v > 1) {
          st += sp + k + "<sup>" + v + "</sup>";
        } else {
          st += sp + k;
        }
        sp = ", ";
      });
      return "diets: (" + st + ")";
    },
    displayDiets(totals) {
      console.log("click on diet: " + totals.Description);
    },
  },
};
</script>

<style>
.notes-info {
  font-family: "Handlee", cursive;
  color: #ca0000;
  font-size: 1.2em;
}
.total-meals-numbers-font {
  font-family: "Handlee", cursive;
  font-size: 1.2em;
  font-weight: 400;
}
.total-meals-numbers-color {
  color: #ca0000;
}
.badge {
  font-size: 90%;
}
</style>