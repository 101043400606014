<style scoped>
.notes-info {
  font-family: "Handlee", cursive;
  color: #ca0000;
  font-size: 1.2em;
}
.cal-badges {
  background-color: #56e6ff;
  border: 1px solid #fafafa;
  border-radius: 5px;
  width: fit-content;
  color: #002222;
  margin-bottom: 2px;
  margin-top: 2px;
  font-size: 0.9em;
  font-weight: 600;
  padding-right: 3px;
  padding-left: 3px;
}
.activities {
  color: #007bff;
}
.cal-badges-yellow {
  background-color: #ffc107;
  border: 1px solid #fafafa;
  border-radius: 5px;
  width: fit-content;
  color: #002222;
  margin-bottom: 2px;
  margin-top: 2px;
  font-size: 0.9em;
  font-weight: 600;
  padding-right: 3px;
  padding-left: 3px;
}
.delete-note-icon {
  cursor: pointer;
}
.feast {
  color: #007bff;
}
.warning {
  color: #dc3545;
}
</style>

<template>
  <b-modal
    @show="resetModal"
    @hide="onHide"
    id="modal-act-day"
    :title="stDate"
    hide-footer
  >
    <icons-modal
      v-on:click-on-icon="clickOnIcon"
      v-on:cancel-icon="cancelIcon"
    ></icons-modal>

    <b-form v-if="!isLoading || !refreshWhileLoading" v-on:submit.prevent>
      <div class="mb-3">
        <div
          v-for="item in calendarDataFeasts"
          :key="item.id"
          class="feast text-center"
        >
          <h5>{{ item.Celebration }} [{{ item.Category }}]</h5>
        </div>
      </div>

      <h5>
        Today Activities
        <small class="text-muted">[From MyNetCal App]</small>
      </h5>

      <b-form-group label>
        <!-- List of Activities from MyNetCalendar -->
        <div class="mb-3">
          <div v-for="item in calendarDataAll" :key="item.StartTime">
            <div class="activities">
              {{ formatTime(item.StartTime) }} {{ formatTitleActivity(item) }}
            </div>
          </div>
        </div>

        <!-- Activities added to the Celendar -->
        <h5 class="mt-4">Other Activities</h5>
        <div v-for="act in calendarDataAct" :key="act.id">
          <div class="activities">
            <span v-if="act.Icon != ''">
              <font-awesome-icon :icon="act.Icon" />
            </span>
            {{ act.Act }}
            <span
              class="pr-1 pl-2 delete-note-icon warning"
              @click="deleteAct(act)"
              v-if="(infoUser.status & 2) > 0"
            >
              <font-awesome-icon icon="calendar-times" />
            </span>
          </div>
        </div>
        <b-input-group v-if="(infoUser.status & 2) > 0" class="mt-2" size="sm">
          <b-input-group-prepend>
            <b-button variant="outline-info" @click="addAct()">Add</b-button>
          </b-input-group-prepend>
          <b-form-input
            type="text"
            v-model="newAct.label"
            placeholder="After typing the activity click on [Add]"
          ></b-form-input>
          <b-input-group-append>
            <b-button
              v-if="newAct.icon == ''"
              variant="outline-info"
              @click="showIcons()"
              >Icon</b-button
            >
            <b-button v-else variant="info" @click="showIcons()">
              <font-awesome-icon :icon="newAct.icon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>

      <!-- Notes for the administration -->
      <h5 class="mt-4">Notes to the Administration</h5>
      <b-form-group label v-if="(infoUser.status & 2) > 0">
        <b-form-textarea
          @update="notesChanged = true"
          v-model="calendarDataNotes"
        ></b-form-textarea>
        <b-button
          variant="outline-warning"
          size="sm"
          @click="onDeleteNote()"
          class="mt-2"
          v-if="calendarDataNotes!=''"
          >Delete Notes</b-button
        >
        <b-alert
          :show="dismissCountDownSaving"
          fade
          variant="success"
          class="mt-2 text-center"
          >Saving</b-alert
        >
        <b-alert
          :show="dismissCountDownSaved"
          fade
          variant="success"
          class="mt-2 text-center"
          >Saved</b-alert
        >
        <b-alert
          :show="dismissCountDownDeleted"
          fade
          variant="warning"
          class="mt-2 text-center"
          >Deleted</b-alert
        >
      </b-form-group>
      <div class="mb-4" v-else>
        <div class="notes-info">{{ calendarDataNotes }}</div>
      </div>

      <div class="text-center">
        <b-button @click="onCancel" variant="info">Close</b-button>
      </div>
    </b-form>
    <div v-else class="text-center activities">
      <font-awesome-icon size="4x" spin icon="sync-alt" />
    </div>
  </b-modal>
</template>

<script>
import moment from "moment";
import IconsModal from "@/components/IconsModal.vue";

export default {
  name: "EditActDay",
  props: {},
  data() {
    return {
      calendarDataFeasts: [],
      calendarDataMass: [],
      calendarDataMyNetCal: [],
      calendarDataNotes: [],
      calendarDataAct: [],
      calendarDataAll: [],
      isLoading: false,
      refreshWhileLoading: true,
      eventsUpdated: false,
      notesChanged: false,
      dateEvents: "",
      newAct: { label: "", icon: "" },
      dismissCountDownSaved: 0,
      dismissCountDownDeleted: 0,
      dismissCountDownSaving: 0,
      dismissSecs: 3,
    };
  },
  computed: {
    stDate() {
      return moment(this.dateEvents).format("dddd, MMMM D");
    },
    infoUser() {
      return this.$store.state.userInfo;
    },
    settings() {
      return this.$store.state.settings;
    },
  },
  components: {
    IconsModal,
  },
  methods: {
    deleteAct(act) {
      act.Act = "---";
      let url = "deleteOtherActivity.php";
      let data = { id: act.id };
      this.refreshWhileLoading = false;
      this.$http.post(url, data, { emulateJSON: true }).then(() => {
        this.newAct = { label: "", icon: "" };
        this.getAllDay(this.dateEvents);
        this.eventsUpdated = true;
      });
    },
    addAct() {
      let url = "addOtherActv2.php";
      let data = { date: this.dateEvents, act: this.newAct };
      this.refreshWhileLoading = false;
      this.$http.post(url, data, { emulateJSON: true }).then(() => {
        this.newAct = { label: "", icon: "" };
        this.eventsUpdated = true;
        this.getAllDay(this.dateEvents);
      });
    },
    onSaveNote(exit = false) {
      var url = "updateNotes.php";
      var data = {
        event: this.calendarDataNotes,
        date: this.dateEvents,
      };
      this.dismissCountDownSaving = this.dismissSecs;
      this.$http.post(url, data, { emulateJSON: true }).then(() => {
        this.eventsUpdated = true;
        this.notesChanged = false;
        this.dismissCountDownSaved = this.dismissSecs;
        this.dismissCountDownSaving = 0;
        if (exit) {
          this.$bvModal.hide("modal-act-day");
        }
      });
    },
    onDeleteNote() {
      this.calendarDataNotes = "";
      this.notesChanged = true;
    },
    onCancel() {
      if (this.notesChanged) {
        this.onSaveNote(true);
      } else {
        this.$bvModal.hide("modal-act-day");
      }
    },
    resetModal() {
      this.notesChanged = false;
      this.eventsUpdated = false;
      this.dateEvents = this.$store.state.dateActEditing;
      this.dismissCountDownSaved = 0;
      this.dismissCountDownDeleted = 0;
      this.dismissCountDownSaving = 0;
      this.getAllDay(this.dateEvents);
    },
    onHide() {
      if (this.eventsUpdated) {
        this.$emit("event-updated");
      }
      this.notesChanged = false;
      this.eventsUpdated = false;
    },
    getAllDay(d) {
      this.isLoading = true;
      this.newAct = { label: "", icon: "" };
      let d2 = moment(d).add(1, "d").format("YYYY-MM-DD");
      let url = "getCalendarAll-v2.php";
      let data = { starts: d, ends: d2 };
      let day = moment(d).date();
      this.$http.post(url, data, { emulateJSON: true }).then(
        (res) => {
          this.calendarDataFeasts = res.data.Feasts[day] || [];
          this.calendarDataMass.push = res.data.Mass[day] || [];
          this.calendarDataMyNetCal = res.data.allPriestEvents[day] || [];
          this.calendarDataAct = res.data.Act[day] || [];
          this.calendarDataNotes = res.data.Events[day]
            ? res.data.Events[day]["Comments"]
            : "";
          this.calendarDataAll = res.data.all[day] || [];
          this.isLoading = false;
          this.refreshWhileLoading = true;
        },
        () => {
          this.isLoading = false;
        }
      );
    },
    formatTime(t) {
      return moment(t, "hh:mm").format("h:mm a");
    },
    formatTitleActivity(activity) {
      let act = activity.ActInitials;
      let aud = activity.AudInitials;
      let pla = activity.PlaInitials;
      let priest = activity.Initials;
      let tit = "";
      act = ["med", "medit", "Med"].includes(act) ? "Meditation" : act;
      act = ["med1", "medit1", "med 1", "rt"].includes(act) ? "d/r" : act;
      act = ["ben", "si ben", "ben si"].includes(act) ? "Benediction" : act;
      act = ["ben sol", "sol ben"].includes(act) ? "Solemn Benediction" : act;
      aud = ["n", "sm"].includes(aud) ? "" : " " + aud;
      tit = act + aud;
      if (this.settings.db.placeInitials != pla) {
        tit += " @ " + pla;
      }
      return tit + " - " + priest;
    },
    isAtCenter(place) {
      return place == this.infoUser.password;
    },
    showIcons() {
      this.$bvModal.show("modal-icons");
    },
    clickOnIcon(el) {
      this.newAct.icon = el;
    },
    cancelIcon() {
      this.newAct.icon = "";
    },
  },
};
</script>