<template>
  <div>
    

    <nav-row />
    <edit-act-day
      v-bind:date-events="currentDayString"
      v-on:event-updated="reloadDate()"
    />

    <div class="float-right" @click="$store.commit('reloadData', true)">
      <font-awesome-icon icon="sync-alt" :style="{ color: 'blue' }" size="lg" />
    </div>
    <!-- Show the table -->
    <div v-if="!loadingTotals" class="totals">
      <total-list-row
        v-for="item in listTotals"
        v-bind:totals="item"
        v-bind:key="item.meal"
        v-bind:past="false"
      />

      <hr class="mb-4" />

      <div v-if="!loadingNotes" class="totals">
        <b-button
          v-if="(infoUser.status & 2) > 0"
          @click="editNotes()"
          variant="info"
        >
          <h5 class="mb-0">Notes for today</h5>
        </b-button>
        <h5 v-else class="mb-0">Notes for today</h5>

        <div>
          <div v-if="todayEvents.comments!=''" class="pt-0 notes-info">{{ todayEvents.comments }}</div>
          <div v-else>---</div>
        </div>

        <div class="mt-2">
          <h5>Coming up</h5>
          <div v-html="todayEvents.coming"></div>
        </div>
      </div>
      <div v-else>
        <font-awesome-icon icon="spinner" spin :style="{ color: 'gray' }" />
      </div>
    </div>
    <div v-else>
      <font-awesome-icon icon="sync-alt" spin size="3x" :style="{ color: 'blue' }" />
    </div>
    <div class="text-left mt-4 text-muted">
      <small>Info updated at {{lastUpdatedTime}}</small>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import NavRow from "./NavRow.vue";
import TotalListRow from "./TotalListRow.vue";
import EditActDay from "./EditActDay-v2.vue";

export default {
  data: function() {
    return {
      listTotals: [],
      todayEvents: {
        act: "",
        comments: "",
        coming: "",
        color: "#ffffff"
      },
      loadingTotals: false,
      loadingNotes: false,
      count: 0,
      lastUpdated: moment(),
      idTimeInterval: 0
    };
  },
  computed: {
    currentDay() {
      return this.$store.state.currentDay;
    },
    currentDayString() {
      return moment(this.$store.state.currentDay).format("YYYY-MM-DD");
    },
    infoUser() {
      return this.$store.state.userInfo;
    },
    reloadData() {
      return this.$store.state.reloadData;
    },
    lastUpdatedTime() {
      return moment(this.lastUpdated).format("h:mm a");
    }
  },
  mounted() {
    this.$store.commit("updateCurrentDayToday");
    this.idTimeInterval = setInterval(this.updateEachHour, 60 * 60 * 1000);
  },
  destroyed() {
    clearInterval(this.idTimeInterval);
  },
  watch: {
    currentDay: function() {
      this.getTotals();
      this.getNotes();
    },
    reloadData: function() {
      if (this.reloadData) {
        this.getTotals();
        this.getNotes();
        this.$store.commit("reloadData", false);
      }
    }
  },
  components: {
    NavRow,
    TotalListRow,
    EditActDay
  },
  methods: {
    getTotals() {
      clearInterval(this.idTimeInterval);
      this.idTimeInterval = setInterval(this.updateEachHour, 60 * 60 * 1000);
      this.lastUpdated = moment();
      this.count++;
      var url = "getTotals-meals.php";
      var st = moment(this.currentDay).format("YYYY-MM-DD");
      var data = { date: st };
      this.listTotals = [];
      this.loadingTotals = true;
      this.$http.post(url, data, { emulateJSON: true }).then(
        res => {
          this.listTotals = res.body;
          this.loadingTotals = false;
          this.count = 0;
        },
        // eslint-disable-next-line no-unused-vars
        err => {
          if (this.count < 10) {
            setTimeout(this.getTotals, 3000);
            setTimeout(this.getNotes, 3000);
            this.$bvToast.toast(`Trying to reconect...`, {
              title: "Connecction lost",
              variant: "info",
              toaster: "b-toaster-top-center",
              autoHideDelay: 2500,
              appendToast: false
            });
          } else {
            this.$bvToast.toast(
              `Sorry, check your Internet connection and try again...`,
              {
                title: "Connecction lost",
                variant: "info",
                toaster: "b-toaster-top-center",
                noAutoHide: true,
                appendToast: false
              }
            );
          }
        }
      );
    },
    getNotes() {
      var url = "getDayNotes.php";
      var st = moment(this.currentDay).format("YYYY-MM-DD");
      var data = { date: st };
      this.loadingNotes = true;
      this.$http.post(url, data, { emulateJSON: true }).then(
        res => {
          this.todayEvents.act = res.body.Events;
          this.todayEvents.comments = res.body.Comments;
          this.todayEvents.coming = res.body.Coming;
          this.todayEvents.color = res.body.Color;
          this.loadingNotes = false;
        },
        // eslint-disable-next-line no-unused-vars
        err => {
          this.todayEvents = {
            act: "",
            comments: "",
            coming: "",
            color: "#ffffff"
          };
          this.loadingNotes = false;
        }
      );
    },
    reloadDate() {
      this.$store.commit("reloadCurrentDay");
    },
    updateEachHour() {
      if (moment().hour() > 4 && moment().hour() < 20) {
        this.$store.commit("updateCurrentDayToday");
      } 
    },
    editNotes() {
      this.$store.commit("updateDateActEditing", this.currentDayString);
      this.$bvModal.show('modal-act-day')
    }
  }
};
</script>

<style scoped>
.totals {
  text-align: left;
}
.notes-info {
  font-family: "Handlee", cursive;
  color: #ca0000;
  font-size: 1.2em;
}
</style>