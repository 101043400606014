<template>
  <div>
    <b-modal id="modal-icons" title="Icons" hide-footer>
      <div>
        <span v-for="iconName in iconsNames" v-bind:key="iconName" class="ml-4">
          <font-awesome-icon
            :icon="iconName"
            size="lg"
            class="mt-4"
            @click="onClick(iconName)"
          />
        </span>
      </div>

      <!-- Buttons -->
      <div class="text-center mt-5">
        <b-button class="ml-1" @click="onCancel()" variant="warning"
          >Cancel</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: [],
  data: function () {
    return {
      //TODO add more icons
      iconsNames: [
        "hamburger",
        "glass-cheers",
        "birthday-cake",
        "arrow-right",
        "arrow-left",
        "arrow-circle-right",
        "arrow-circle-left",
        "arrows-alt-h",
        "campground",
        "fire",
        "hiking",
        "tree",
        "beer",
        "wine-bottle",
        "clock",
        "ghost",
        "snowman",
        "couch",
        "film",
        "cross",
        "thumbs-up",
        "exclamation",
        "sync-alt",
        "slash",
        "spinner",
        "file-export",
        "cog",
        "calendar-times",
        "eye",
        "baby",
        "balance-scale",
        "baseball-ball",
        "basketball-ball",
        "bed",
        "bell",
        "biking",
        "binoculars",
        "blind",
        "bolt",
        "book",
        "book-reader",
        "boxes",
        "bug",
        "broom",
        "bus",
        "calendar",
        "candy-cane",
        "car",
        "cat",
        "cheese",
        "chess",
        "church",
        "cocktail",
        "coffee",
        "dice",
        "dna",
        "dog",
        "dollar-sign",
        "dove",
        "drum",
        "envelope",
        "fingerprint",
        "football-ball",
        "futbol",
        "ice-cream",
        "map-marked",
        "mask",
        "music",
        "pizza-slice",
        "plane",
        "pray",
        "question",
        "snowboarding",
        "smoking",
        "socks",
        "subway",
        "table-tennis",
        "puzzle-piece",
        "swimmer",
        "stopwatch",
        "gifts",
        "gift",
        "plane-departure",
        "plane-arrival"
      ],
    };
  },
  computed: {},
  components: {},
  watch: {},
  mounted() {},
  created() {},
  methods: {
    onCancel: function () {
      this.$emit("cancel-icon");
      this.$bvModal.hide("modal-icons");
    },
    onClick: function (iconName) {
      this.$emit("click-on-icon", iconName);
      this.$bvModal.hide("modal-icons");
    },
  },
};
</script>

<style lang="scss">
</style>
