<style scoped>
.background-date {
  background-color: #d0f0ff;
  border-radius: 5px;
  border: none;
  width: 100%;
  text-align: center;
}

.background-date-today {
  background-color: #f0d0ff;
  border-radius: 5px;
  border: none;
  width: 100%;
  text-align: center;
}
</style>

<template>
  <b-row align-v="center" class="text-center">
    <b-col cols="2">
      <b-button style="color: blue; background:none; border:none" @click="weekAdd(-1)">
        <font-awesome-icon icon="caret-left" :style="{ color: 'blue' }" size="3x" />
      </b-button>
    </b-col>
    <b-col v-bind:cols="isCurrentWeek ? 8 : 7 ">
      <h5
        v-bind:class="isCurrentWeek ? 'background-date-today' : 'background-date'"
      >{{ stWeek }}</h5>
    </b-col>
    <b-col cols="1" v-if="!isCurrentWeek">
      <b-button style="background:none; border:none" @click="goToToday()">
        <font-awesome-icon icon="undo" :style="{ color: 'blue' }" />
      </b-button>
    </b-col>
    <b-col cols="2">
      <b-button style="color: blue; background:none; border:none" @click="weekAdd(1)">
        <font-awesome-icon icon="caret-right" :style="{ color: 'blue' }" size="3x" />
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import moment from "moment";

export default {
  computed: {
    week: {
      get() {
        return this.$store.getters.currentWeek;
      },
      set(value) {
        this.$store.commit("updateCurrentWeek", value);
      }
    },
    stWeek() {
      var st1 = moment(this.week).format("MMMM D");
      var st2 = moment(this.week)
        .add(1, "w").subtract(1,"d")
        .format("D");
      if (
        moment(this.week).month() !=
        moment(this.week)
          .add(1, "w")
          .month()
      ) {
        st2 = moment(this.week)
          .add(1, "w").subtract(1,"d")
          .format("MMMM D");
      }
      return st1 + " - " + st2;
    },
    isCurrentWeek: function() {
      return moment()
        .startOf("week")
        .isSame(moment(this.week).startOf("week"));
    }
  },
  mounted() {},
  methods: {
    weekAdd: function(num) {
      this.week = moment(this.week)
        .add(num, "w")
        .format("YYYY-MM-DD");
    },
    goToToday: function() {
      this.week = moment()
        .startOf("week")
        .format("YYYY-MM-DD");
    }
  }
};
</script>