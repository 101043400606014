<template>
  <div>
    <header-app />

    <div>
      <b-tabs content-class="mt-3" v-model="tabIndex" fill>
        <b-tab title="Day">
          <TotDay />
        </b-tab>
        <b-tab title="Week">
          <TotWeek v-bind:tab="tabIndex" />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import TotDay from "@/components/TotDay.vue";
import TotWeek from "@/components/TotWeek.vue";
import HeaderApp from "@/components/HeaderApp.vue";


export default {
  data: function() {
    return {
      tabIndex: 0
    };
  },
  computed: {},
  mounted() {},
  destroyed() {},
  watch: {},
  components: {
    TotDay,
    TotWeek,
    HeaderApp
  },
  methods: {}
};
</script>

<style>
</style>