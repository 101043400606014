<template>
  <div v-bind:class="{ 'small-window': isSmallDisplay }">
    <nav-week-row />

    <div style="width: 100%; overflow: auto;">
      <div style="min-width:580px">
        <div v-if="!loadingTotals" class="mt-3 font-small">
          <b-row no-gutters>
            <b-col cols="3"></b-col>
            <b-col
              v-for="day in days"
              v-bind:key="day.day"
              v-bind:class="{
                'is-today-feast': isToday(day.day) && day.feast != '',
              }"
            >
              <div
                class
                v-bind:class="{
                  'is-before': isBefore(day.day),
                  'title-feast': !isBefore(day.day),
                  'title-feast-a': day.feast == 'A' && !isBefore(day.day),
                }"
              >
                {{ day.feast }}
              </div>
            </b-col>
          </b-row>
          <b-row no-gutters class="mt-1">
            <b-col cols="3"></b-col>
            <b-col
              v-for="day in days"
              v-bind:key="day.day"
              class="title-day"
              v-bind:class="{ 'is-today-top': isToday(day.day) }"
            >
              <div v-bind:class="{ 'is-before': isBefore(day.day) }">
                {{ dayFormat(day.day, "ddd") }}
              </div>
            </b-col>
          </b-row>
          <b-row no-gutters class="mb-2">
            <b-col cols="3"></b-col>
            <b-col
              v-for="day in days"
              v-bind:key="day.day"
              class="title-day"
              v-bind:class="{ 'is-today-bottom': isToday(day.day) }"
            >
              <div v-bind:class="{ 'is-before': isBefore(day.day) }">
                <div>{{ dayFormat(day.day, "D") }}</div>
              </div>
            </b-col>
          </b-row>

          <tot-week-row-meals
            v-for="item in listTotals"
            v-bind:totals="item"
            v-bind:key="item.meal"
            v-bind:past="false"
          />
        </div>
        <div v-else>
          <font-awesome-icon icon="spinner" spin :style="{ color: 'gray' }" />
        </div>
      </div>
    </div>

    <div
      v-if="infoUser.status > 0"
      v-bind:class="{ 'small-window': isSmallDisplay }"
    >
      <div v-if="!gettingLastEntryCurrentUser" class="totals mt-5 mb-5">
        <div v-if="missingThisWeek.length > 0">
          <h5 class="totals missing">People missing info:</h5>
          <li v-for="item in missingThisWeek" v-bind:key="item.id">
            {{ item.UserName }} -
            {{ dayFormat(item.DateMissing, "dddd, MMMM D...") }}
          </li>
        </div>
        <div v-else>
          <h5 class="totals no-missing">No people missing info</h5>
        </div>
      </div>
      <div v-else>
        <font-awesome-icon icon="spinner" spin :style="{ color: 'gray' }" />
      </div>
    </div>

    <div
      v-if="!loadingNotes"
      class="totals mt-3"
      v-bind:class="{ 'small-window': isSmallDisplay }"
    >
      <div class="mt-2">
        <h5>This week</h5>
        <div v-html="notesWeek"></div>
      </div>
    </div>
    <div v-else>
      <font-awesome-icon icon="spinner" spin :style="{ color: 'gray' }" />
    </div>
  </div>
</template>

<script>
import NavWeekRow from "./NavWeekRow.vue";
import TotWeekRowMeals from "./TotWeekRowMeals.vue";
import moment from "moment";
import Vue from "vue";

export default {
  props: ["tab"],
  data: function () {
    return {
      listTotals: [],

      lastEntryMissing: [],
      notesWeek: [],
      loadingTotals: true,
      loadingNotes: true,
      gettingLastEntryCurrentUser: true,
      count: 0,
      lastUpdated: moment(),
      idTimeInterval: 0,
      tabIndex: this.tab,
      days: [],
    };
  },
  computed: {
    currentWeek() {
      return this.$store.getters.currentWeek;
    },
    missingThisWeek() {
      let d2 = moment(this.currentWeek).add(7, "d");
      return this.lastEntryMissing.filter((o) =>
        moment(o.DateMissing).isBefore(d2)
      );
    },
    infoUser() {
      return this.$store.state.userInfo;
    },
    isSmallDisplay() {
      return this.$store.state.isSmallWindow;
    },
  },
  components: {
    NavWeekRow,
    TotWeekRowMeals,
  },
  mounted() {
    this.idTimeInterval = setInterval(this.updateEachHour, 60 * 60 * 4 * 1000);
    this.calculateDates();
    this.getTotalsMeals();
    this.updateLastDayMissing();
    this.getNotes();
  },
  destroyed() {
    clearInterval(this.idTimeInterval);
  },
  watch: {
    currentWeek: function () {
      this.calculateDates();
      this.getTotalsMeals();
      this.getNotes();
    },
    tab: function () {
      if (this.tab == 1) {
        this.getTotalsMeals();
        this.updateLastDayMissing();
        this.getNotes();
      }
    },
  },
  methods: {
    getTotalsMeals() {
      clearInterval(this.idTimeInterval);
      this.idTimeInterval = setInterval(
        this.updateEachHour,
        4 * 60 * 60 * 1000
      );
      var url = "getTotalsWeek-meals.php";
      var st = moment(this.currentWeek).format("YYYY-MM-DD");
      this.loadingTotals = true;
      var data = { date: st };
      this.listTotals = [];
      this.$http.post(url, data, { emulateJSON: true }).then(
        (res) => {
          this.loadingTotals = false;
          this.count = 0;

          this.listTotals = res.body;
        },
        // eslint-disable-next-line no-unused-vars
        (err) => {}
      );
    },
    getTotals() {
      clearInterval(this.idTimeInterval);
      this.idTimeInterval = setInterval(
        this.updateEachHour,
        4 * 60 * 60 * 1000
      );
      var url = "getTotalsWeek-meals.php";
      var st = moment(this.currentWeek).format("YYYY-MM-DD");
      this.loadingTotals = true;
      var data = { date: st };
      this.$http.post(url, data, { emulateJSON: true }).then(
        (res) => {
          this.loadingTotals = false;
          this.count = 0;

          this.listTotals = [];

          for (let index = 0; index < res.body.length; index++) {
            const meal = res.body[index].totals;
            let b = [];
            let foundIndex = 0;
            let found = {};
            let cX = false;
            let cL = false;
            let cP = false;
            let cE = false;
            this.days.forEach((day) => {
              let Diets = "";
              let totX = 0;
              let totL = 0;
              let totP = 0;
              let totE = 0;
              let dietX = "";
              let dietL = "";
              let dietP = "";
              let dietE = "";
              while (
                (foundIndex = meal.findIndex(({ Date }) => Date == day.day)) >=
                0
              ) {
                found = meal.splice(foundIndex, 1);
                let el = found[0];

                let a = el.Diets.split("|");
                let m = new Map();
                a.forEach(function (x) {
                  m.set(x, (m.get(x) || 0) + 1);
                });
                let st = "";
                let sp = "";
                m.forEach(function (v, k) {
                  if (v > 1) {
                    st += sp + v + "-" + k;
                  } else {
                    st += sp + k;
                  }
                  sp = ", ";
                });
                el.Diets = st;
                Diets = el.Diets;
                switch (el.Status) {
                  case "X":
                    cX = true;
                    totX = el.Total;
                    dietX = el.Diets;
                    break;
                  case "L":
                    cL = true;
                    totL = el.Total;
                    dietL = el.Diets;
                    break;
                  case "P":
                    cP = true;
                    totP = el.Total;
                    dietP = el.Diets;
                    break;
                  case "E":
                    cE = true;
                    totE = el.Total;
                    dietE = el.Diets;
                    break;

                  default:
                    break;
                }

                //b.push({ Total: 0, Status: "", Diets: "", Date: day });
              }
              b.push({
                Total: totX,
                totX: totX,
                totL: totL,
                totP: totP,
                totE: totE,
                Diets: Diets,
                dietX: dietX,
                dietL: dietL,
                dietP: dietP,
                dietE: dietE,
                Date: day.day,
              });
            });
            this.listTotals.push({
              meal: res.body[index].meal,
              X: cX,
              L: cL,
              P: cP,
              E: cE,
              totals: b,
            });
          }
        },
        // eslint-disable-next-line no-unused-vars
        (err) => {}
      );
    },
    updateLastDayMissing() {
      var url = "getLastDayMissing.php";
      var data = { date: moment(this.currentDay).format("YYYY-MM-DD") };
      this.gettingLastEntryCurrentUser = true;
      this.$http.post(url, data, { emulateJSON: true }).then(
        (res) => {
          if (res.body.Missing) {
            this.lastEntryMissing = res.body.Missing;
          } else {
            this.lastEntryMissing = [];
          }

          this.gettingLastEntryCurrentUser = false;
        },
        // eslint-disable-next-line no-unused-vars
        (err) => {}
      );
    },
    dayFormat(date, st) {
      return moment(date).format(st);
    },
    getNotes() {
      var url = "getWeekNotes.php";
      var st = moment(this.currentWeek).format("YYYY-MM-DD");
      var data = { date: st };
      this.loadingNotes = true;
      this.$http.post(url, data, { emulateJSON: true }).then(
        (res) => {
          this.notesWeek = res.body.Coming;
          this.loadingNotes = false;
        },
        // eslint-disable-next-line no-unused-vars
        (err) => {
          this.loadingNotes = false;
        }
      );
    },
    calculateDates() {
      let d = moment(this.$store.getters.currentWeek);
      this.days = [{ day: d.format("YYYY-MM-DD"), feast: "" }];
      for (let index = 1; index < 7; index++) {
        this.days.push({ day: d.add(1, "d").format("YYYY-MM-DD"), feast: "" });
      }
      this.getFeastDays();
    },
    getFeastDays() {
      const url = "getWeekFeastDays.php";
      const st = moment(this.currentWeek).format("YYYY-MM-DD");
      const data = { date: st };
      this.$http.post(url, data, { emulateJSON: true }).then(
        (res) => {
          let cel = res.body.Feasts;

          let indexDays = 0;
          for (let index = 0; index < cel.length; index++) {
            const element = cel[index];

            while (this.days[indexDays].day != element.Date && indexDays <= 7) {
              indexDays++;
            }
            if (this.days[indexDays].day == element.Date) {
              //this.days[indexDays].feast = element.Cat;
              Vue.set(this.days, indexDays, {
                day: element.Date,
                feast: element.Cat,
                //feast: element.Cat
              });
            }
          }
        },
        () => {}
      );
    },
    isBefore(d) {
      return moment(d).isBefore(moment(), "day");
    },
    updateEachHour() {
      if (moment().hour() > 4 && moment().hour() < 20) {
        this.getTotalsMeals();
        this.updateLastDayMissing();
        this.getNotes();
      }
    },
    isToday(d) {
      return moment(d).isSame(moment(), "day");
    },
  },
};
</script>

<style lang="scss" scoped>
$bg-today: rgb(200, 255, 255);
$bg-today2: rgb(127, 233, 233);

.totals {
  text-align: left;
}
.cel-center {
  text-align: center;
}
.no-missing {
  color: green;
}
.missing {
  color: red;
}
.is-before {
  color: rgb(182, 182, 182);
  font-weight: 400;
}
.is-today-top {
  background-color: $bg-today2;
  border-radius: 5px 5px 0px 0px;
}
.is-today-bottom {
  background-color: $bg-today2;
  border-radius: 0px 0px 5px 5px;
}
.is-today-feast {
  background-color: $bg-today2;
  border-radius: 5px 5px 5px 5px;
}
.title-day {
  color: rgb(48, 48, 182);
}
.title-feast {
  color: #0a7700fb;
  background-color: #b2eead;
  font-weight: 600;
}
.title-feast-a {
  color: #d2ebd0fb;
  background-color: #042e00;
  font-weight: 600;
}
.border-meals {
  border-top-width: 3px;
}
.font-small {
  font-size: 0.8em;
}
.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
}
.small-window {
  font-size: 0.9em;
}
</style>